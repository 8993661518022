body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
}

html {
  scroll-snap-type: y mandatory;
  height: 100%;
}

.app {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.app__header {
  color: white;
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 600;
  max-width: 500px;
}

.app__githubLogo svg {
  fill: white;
}

.app__error {
  color: white;
  padding: 30px;
  font-size: 14px;
}

.app__videos {
  position: relative;
  flex: 1;
  border-radius: 20px;
  overflow: scroll;
  width: 100%;
  max-width: 500px;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
}

.app__videos.app__videos--loading {
  scroll-snap-type: none;
}

/* .app__backdrop.show {
  display: flex;
} */

/* Hide scrollbar for Chrome, Safari & Opera */
.app__videos::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge & Firefox */
.app__videos {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app__footer {
  color: white;
  font-size: 12px;
  margin: 0 auto;
  padding: 12px 0;
}

.app__footer a {
  color: white;
}
