.videoFooter {
  position: relative;
  bottom: 150px;
  margin-left: 24px;
  color: white;
  display: flex;
}

.videoFooter__text {
  flex: 1;
}

.videoFooter__text > p {
  padding-bottom: 20px;
}

.videoFooter__text > h3 {
  padding-bottom: 20px;
}

.videoFooter__icon {
  position: absolute;
  bottom: -4px;
}

.videoFooter__ticker > .ticker {
  height: fit-content;
  margin-left: 30px;
  width: 68%;
}

.videoFooter__record {
  height: 40px;
  filter: invert(1);
  position: absolute;
  bottom: -13px;
  right: 18px;
  animation: spinTheRecord infinite 5s linear;
}

@keyframes spinTheRecord {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
