.loading-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  transition: all 350ms ease-in;
}

.loading-video.show {
  opacity: 1;
  visibility: visible;
}
