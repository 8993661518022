.video {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  font-size: 12px;
}

.video__player {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 481px) {
  .video {
    font-size: 14px;
  }
}
